<template>
	<div>
		<slot />
	</div>
</template>

<script setup>
useHead({
	title: 'Just Move In - Partner Portal',
	viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
	charset: 'utf-8',
	meta: [
		{name: 'description', content: 'Just Move In - Partner Portal'}
	],
	bodyAttrs: {
		class: 'h-full'
	},
	htmlAttrs: {
		class: 'h-full bg-jmi-purple1'
	}
})

</script>
